<template>
	<div class="Guide">
		<img class="img" :src="img+'?v='+Math.random()" @click="goAplat" alt="">
	</div>
</template>

<script>
	import {
		getAppleLinkCode
	} from "@/api/index";
	export default {
		data() {
			return {
				img: ''
			};
		},
		created() {
			this.img_index = this.$route.query.index;
			if (this.$app == "kh") {
				this.img = "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/kh-guide.png.png"
			} else if (this.$app == "dy") {
				this.img = "https://jiaqi.obs.cn-north-1.myhuaweicloud.com/pc/dy-guide.png"
			}
		},
		methods: {
			goAplat() {
				// window.location.href=;
				if (this.$app == "kh") {
					var applet = 3
				} else if (this.$app == "dy") {
					var applet = 2
				}
				getAppleLinkCode(applet).then((res) => {
					console.log(res)
					// window.location.href = res.openlink
					window.location.href = res.url_link
				});
			}
		}
	};
</script>

<style scoped>
	.img {
		width: 100%;
	}
</style>